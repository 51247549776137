import _ from 'lodash';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import PropTypes from 'prop-types';
import React from 'react';
import * as ERROR from '../../../../constants/error';
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import KeyWord from './KeyWord';
import Share from './Share';
import PlayerModule from './PlayerModule';
import SuggestArticleList from './SuggestArticleList';
import { getGenres2Names, getGenres2Code, getArticle2ProviderCode } from '../../../../common/getGenreCategories';
import getImage from '../../../../common/getImage';
import getResizedImage from '../../../../common/getResizedImage';
import { NotFoundError, CongestionError } from '../../../common/components/ErrorBoundary';
import url from 'url';
import Axios from '../../../../common/Axios';
import routes from '../../../common/routes';
import { getArticleResizeImage, urlSizeFormat, isResizable } from '../../../../common/preloadImages';
import { MAX_WIDTH_IMAGE } from '../../../../constants/config';
import { GetSourceProjectId } from '../../../../common/getSourceProjectId';
import getBroadcasterData from '../../../../common/getBroadcasterData';
import { GetArticleLinkId } from '../../../../common/getSourceProjectId';
import removeTag from '../../../../common/react-removeTag';
import getDate from '../../../../common/getDate';
import GetPageSchemaObjects from '../../../common/utils/getPageSchemaObjects';

class ArticlesContent extends React.Component {
  static contextTypes = {
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    models: PropTypes.object,
    history: PropTypes.object,
    isSpaError: PropTypes.func,
    getModelData: PropTypes.func,
    projectRefIdMapping: PropTypes.object,
  };

  static getPaths = function (models, options, props) {
    const rootPath = [this.getRootPath(models, options, props)];
    const companyCode = _.get(props, 'companyCode', 'ntv');
    const path = props.routeHandler.path;
    rootPath.push(['article', 'getSeo'], ['article', 'suggest', companyCode, path]);

    return rootPath;
  };

  static getRootPath = function (models, options, props) {
    const articleId = _.get(props, 'id');
    const isTrentaUiType = !_.get(props.routeHandler, 'isEachCompany', false);
    return ['article', articleId, isTrentaUiType];
  };

  static getPrefetchPaths = function (models, options, props) {
    return this.getPaths(models, options, props);
  };

  getParagraphLength = (paragraph) => {
    if (paragraph.type === 'embed' || paragraph.type === 'image') {
      return 0;
    }

    const bodyLength =
      paragraph.type === 'rich_text' ? removeTag(paragraph.value).length : paragraph.value?.length || 0;

    if (paragraph.title) {
      return bodyLength + paragraph.title.length;
    }
    return bodyLength;
  };

  checkIfLengthExceedsThreshold = () => {
    const threshold = _.get(this.seoJson, 'noindexThreshold') || 1;
    const paragraphs = _.get(this, 'item.paragraphs', []);
    const paragraphLengths = paragraphs.map(this.getParagraphLength);
    const totalLength = paragraphLengths.reduce((accumulator, currentValue) => {
      return accumulator + currentValue;
    }, 0);
    return totalLength <= threshold;
  };

  static afterPrefetch = function (models, options, props) {
    return (prefetchResult) => {
      const rootPath = this.getRootPath(models, null, props);
      const item = _.get(prefetchResult, ['json'].concat(rootPath));
      if (!item || (_.get(props.routeHandler, 'isEachCompany') && _.get(item.source_systems, 'project') === 'n24')) {
        console.error('記事が存在しません');
        return { error: ERROR.NOTFOUND }; // 記事が存在しない場合にpreload時にエラーコンポーネントにリダイレクト
      }
      return null;
    };
  };

  constructor(props, context) {
    super(props, context);

    this.handlePlayButton = this.handlePlayButton.bind(this);
    this.isEachCompany = _.get(context, 'routeHandler.isEachCompany', false);
    this.projectRefIdMapping = _.get(context, 'projectRefIdMapping');
    const rootPath = this.constructor.getRootPath(context.models, null, props);
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.item = _.cloneDeep(this.model.getSync(rootPath));
    this.seoJson = this.model.getSync('article.getSeo');
    this.articleMetaRobots = _.get(this.seoJson, 'articleMetaRobots', '');
    this.isExistArticleMetaRobots = !_.isEmpty(`${this.articleMetaRobots}`.trim());
    // 本文が100文字以下の場合はnoindexを付与
    this.isNoindex = this.checkIfLengthExceedsThreshold();
    // 一緒に見られているニュース
    const companyCode = _.get(props, 'companyCode', 'ntv');
    const path = props.routeHandler.path || context.routeHandler.path;
    this.articles = this.model.getSync(['article', 'suggest', companyCode, path]);

    if (this.item) {
      // Category(Genre)
      const providerCode = !this.isEachCompany ? 'ntv' : getArticle2ProviderCode(this.item);
      this.item.categoryTags = getGenres2Names(
        _.get(this.item, 'genres'),
        _.get(context, 'models.config.data'),
        providerCode,
      );
      this.item.categoryCode = getGenres2Code(
        _.get(this.item, 'genres'),
        _.get(context, 'models.config.data'),
        providerCode,
      );
      // ハッシュタグ
      this.item.hashTag = _.get(this.item, 'hash_tags') || _.get(this.item, 'tags.hash'); // hash_tagsデータが存在しない場合、タググループデータを利用

      // 記事データをContextに格納。広告データに使用するため(GoogleAdManager.js)
      this.context.models.articleData = this.item;
    }
    this.AD = _.get(this.item, 'categoryCode') === 'sponsored' ? '【AD】' : '';
    if (_.get(this.item, 'hashTag')) this.replaceHashTagInParagraphs();
    this.diableRecommend = false;
    if (_.get(this.item, 'custom_data.not_recommend_flag')) {
      this.diableRecommend = true;
    }

    // 関連記事取得・リンクURL設定
    this.relatedArticles = _.map(_.get(this.item, 'relatedArticles'), (article) => {
      // 関連リンクの場合（article_idを持たない・title/urlを持つ場合）
      if (!_.get(article, 'article_id') && _.get(article, 'title') && _.get(article, 'url')) {
        return article;
      }

      // 関連記事の場合
      const categoryCode = getGenres2Code(
        _.get(article, 'genres'),
        _.get(context, 'models.config.data'),
        getArticle2ProviderCode(article),
      );
      const providerPrefixes = _.get(this.context, 'models.config.data.providerPrefix');
      const articleId4Url = GetArticleLinkId(article, providerPrefixes);
      const routeParam = this.isEachCompany
        ? {
            title: article.title,
            to: _.isEmpty(categoryCode) ? routes.articlesEachCompany : routes.articleDetailEachCompany,
            params: { id: articleId4Url, companyCode, categoryCode },
          }
        : {
            title: article.title,
            to: _.isEmpty(categoryCode) ? routes.articles : routes.articleDetail,
            params: { id: articleId4Url, categoryCode },
          };
      return routeParam;
    });

    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.setDefault('Asia/Tokyo');

    const now = dayjs().tz().format();
    let viewPlayerBtn = false;
    if (_.get(this.item, 'media') && _.get(this.item, 'media.media_id')) {
      const publishStartAt = _.get(this.item, 'media.publish_start_at', '1970-01-01T00:00:00+00:00');
      const publishEndAt = _.get(this.item, 'media.publish_end_at', '2999-12-31T23:59:59+00:00');
      if (publishStartAt <= now && publishEndAt >= now) {
        viewPlayerBtn = true;
      }
    }

    // preload imageの設定
    // 記事最上部の画像取得
    if (!this.hasYoutubePlayer(this.item)) {
      let articleImage = getArticleResizeImage(this.item);
      // viewPlayerBtn(Player)あり時、デフォルトサムネイル画像を取得
      if (!articleImage && viewPlayerBtn) {
        const articleDefaultImage = getImage({ itemData: this.item }, this.context);
        articleImage = isResizable(articleDefaultImage)
          ? urlSizeFormat(articleDefaultImage, MAX_WIDTH_IMAGE)
          : articleDefaultImage;
      }
      if (!_.isEmpty(articleImage)) this.props.setPreloadImages(articleImage);
    }

    // 記事内の画像取得
    if (_.get(this.item, 'paragraphs')) {
      const paragraphImages = _.compact(
        _.get(this.item, 'paragraphs')
          .filter((e) => e.type === 'image' && _.get(e, 'url'))
          .map((itemData) => {
            const urlParse = url.parse(itemData.url, true);
            urlParse.query.w = MAX_WIDTH_IMAGE;
            return url.format(urlParse);
          }),
      );
      if (!_.isEmpty(paragraphImages)) this.props.setPreloadImages(paragraphImages);
    }

    this.axios = new Axios({ xhr: true });

    this.state = {
      isLoaded: false,
      viewPlayerBtn,
      articleProjectId: this.isEachCompany ? GetSourceProjectId(this.item) : 'n24',
    };
  }

  hasYoutubePlayer(item) {
    const youtubeVideoRestrictCategories = _.get(item, 'youtubeVideoRestrictCategories');
    if (!youtubeVideoRestrictCategories || _.get(youtubeVideoRestrictCategories, _.get(item, 'categoryCode'), true)) {
      return false;
    }
    if (_.get(item, 'custom_data.streaks_flag')) return false;
    if (_.get(item, 'custom_data.__youtube_url')) {
      let parsedUrl;
      try {
        parsedUrl = url.parse(_.get(item, 'custom_data.__youtube_url'), true);
      } catch (e) {}
      const media = _.get(parsedUrl, 'query.__media');
      const currentMediaId = _.get(item, 'media.media_id');
      if (currentMediaId && media && media !== currentMediaId) {
        return false;
      } else if (!currentMediaId && media) {
        return false;
      }
    } else {
      return false;
    }
    return true;
  }

  // 記事本文に含まれるハッシュタグに合致する文字列をリンクに置換
  replaceHashTagInParagraphs() {
    for (let i = 0; i < this.item.hashTag.length; i++) {
      for (let j = 0; j < this.item.paragraphs.length; j++) {
        if (this.item.paragraphs[j].type === 'text' || this.item.paragraphs[j].type === 'rich_text') {
          if (this.item.paragraphs[j].value.includes(this.item.hashTag[i])) {
            // ハッシュタグに含まれる特殊文字にエスケープ処理をしておく
            const escapedHashTag = this.item.hashTag[i].replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            // aタグ（taglink）に囲まれていないハッシュタグ（文字列）を置換処理
            const hashTagRegex = new RegExp('(?!<a class="taglink")' + escapedHashTag + '(?![^<]*</a>)');
            const urlEscapedHashTagLink = encodeURIComponent(this.item.hashTag[i]);
            this.item.paragraphs[j].value = this.item.paragraphs[j].value.replace(
              hashTagRegex,
              `<a class="taglink" href="/tag/${urlEscapedHashTagLink}">${this.item.hashTag[i]}</a>`,
            );
            if (this.item.paragraphs[j].type === 'text') {
              this.item.paragraphs[j].type = 'rich_text';
              this.item.paragraphs[j].value = _.replace(this.item.paragraphs[j].value, /\r\n|\n|\r/g, `<br />`);
            }
            break;
          }
        }
      }
    }
  }

  getBreadHome() {
    const defaultHome = {
      to: routes.home,
      params: {
        title: '日テレNEWS NNN',
      },
    };
    if (!this.isEachCompany) return defaultHome;

    const company = _.get(this.props.routeHandler, 'params.companyCode', 'n24');
    const broadCast = getBroadcasterData(company);
    return {
      to: routes.companyHome,
      params: {
        title: broadCast.nnn,
        companyCode: broadCast.title,
      },
    };
  }

  // 各社UIではカテゴリパンくず表示なし
  getCategoryParams() {
    const categoryTitle = _.get(this.item, 'categoryTags.0');
    return {
      to: routes.newTrenta,
      params: {
        title: categoryTitle,
        categoryCode: this.item.categoryCode,
      },
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * 動画の再生ボタンが押された時の処理
   */
  handlePlayButton() {
    if (_.get(this.item, 'media.media_id') && _.get(this.state, 'viewPlayerBtn') && !this.state.isLoaded) {
      try {
        const projectRefId = _.get(this.state, 'articleProjectId');
        if (!projectRefId) {
          throw new Error(`対応するプロジェクト参照IDがありません。projectRefId: ${projectRefId}`);
        }
        const hostname = this.context.getModelData('services', 'playback', 'hostname');
        const playbackService = {
          name: 'papi',
          protocol: 'https',
          hostname: hostname,
          path: ['v1', 'projects', projectRefId, 'medias'],
        };
        playbackService.pathname = _.join(_.concat(playbackService.path, this.item.media.media_id), '/');
        this.axios.get(url.format(playbackService), {}, {}).then((result) => {
          const media = _.get(result, 'data');
          if (media) {
            this.item.media = _.merge({}, this.item.media, media);
            this.setState({
              isLoaded: true,
              viewPlayerBtn: false,
            });
          }
        });
      } catch (e) {
        console.error('handlePlayButton event error : ', e);
      }
    }
  }

  render() {
    if (!this.item) {
      if (_.get(this.context, 'isSpaError')) {
        this.context.isSpaError();
      }
      throw new NotFoundError();
    } else if (_.get(this.item, 'errCode') === 'ECONNABORTED') {
      if (_.get(this.context, 'isSpaError')) {
        this.context.isSpaError();
      }
      throw new CongestionError();
    }

    const image = getResizedImage({ itemData: this.item }, this.context, MAX_WIDTH_IMAGE);

    const date = getDate(this.item);
    const dayjsFormatDate = _.get(this.item, 'categoryCode') === 'sponsored' ? '':`（${dayjs(date).format('YYYY年M月D日')}掲載）`;

    let gaTitle = _.get(this.item, 'title', '記事詳細');
    if (_.get(this.item, 'first_publish_date')) {
      gaTitle = `${gaTitle}｜${dayjs(this.item.first_publish_date).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss')}`;
    }

    const providerPrefixes = _.get(this.context, 'models.config.data.providerPrefix');
    const articleId4Url = GetArticleLinkId(this.item, providerPrefixes);
    const provider = _.get(this.item, 'source_systems.project');
    const companyCode = getBroadcasterData(provider).title;

    let canonical = `/articles/${articleId4Url}`;
    const categoryCode = _.get(this.item, 'categoryCode');
    if (categoryCode) {
      canonical = `/category/${categoryCode}/${articleId4Url}`;
      if (companyCode !== 'n24') {
        // 出稿局がn24以外の場合、canonicalを各社UIの記事詳細ページにする。
        canonical = `/n/${companyCode}/category/${categoryCode}/${articleId4Url}`;
      }
    }

    const getPageSchemaObjects = new GetPageSchemaObjects({
      item: this.item,
      canonical,
      image
    }, this.context);
    // 記事構造化データ
    const newsArticleObj = getPageSchemaObjects.generateArticleSchemaObject();
    // パンくずリスト構造化データ
    const breadCrumbObj = getPageSchemaObjects.generateBreadCrumbSchema();

    const ldJsonList = [
      {
        type: 'application/ld+json',
        innerHTML: JSON.stringify(newsArticleObj),
      },
    ];

    if (breadCrumbObj) {
      ldJsonList.push({
        type: 'application/ld+json',
        innerHTML: JSON.stringify(breadCrumbObj),
      });
    }

    const company = _.get(this.props.routeHandler, 'params.companyCode', 'n24');
    const bloadcaster = getBroadcasterData(company);
    const titleTag = company === 'n24' ? '日テレNEWS NNN' : `${bloadcaster.nnn}`;

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ shortTitle }) => {
            const metas = [];

            let firstParagraph;
            for (let i = 0; i < this.item.paragraphs.length; i++) {
              if (this.item.paragraphs[i].type === 'text') {
                firstParagraph = removeTag(this.item.paragraphs[i].value).split(/\n\n/)[0];
                break;
              } else if (this.item.paragraphs[i].type === 'rich_text') {
                const $firstParagraph = this.item.paragraphs[i].value.split('<br><br>')[0];
                firstParagraph = removeTag($firstParagraph);
                break;
              }
            }

            metas.push({
              property: 'og:title',
              content: shortTitle(`${this.AD}${_.get(this.item, 'title')}｜${titleTag}`),
            });
            metas.push({ property: 'og:type', content: 'article' });
            if (_.get(this.item, 'summary')) {
              metas.push({ name: 'description', content: firstParagraph });
              metas.push({ property: 'og:description', content: firstParagraph });
            }

            if (image) {
              metas.push({ property: 'og:image', content: image });
              metas.push({ property: 'og:image:width', content: MAX_WIDTH_IMAGE });
              metas.push({ property: 'og:image:height', content: (MAX_WIDTH_IMAGE / 16) * 9 });
            }

            metas.push({
              property: 'cXenseParse:recs:publishtime',
              content: dayjs(date).tz().format(),
            });
            metas.push({
              property: 'cXenseParse:recs:articleid',
              content: GetArticleLinkId(this.item, _.get(this.context, 'models.config.data.providerPrefix', {})),
            });
            metas.push({ property: 'article:modified_time', content: _.get(this.item, 'updated_at') });
            if (this.diableRecommend) {
              metas.push({ property: 'ntvnews-recommendable-article', content: 'false' });
              metas.push({ property: 'cXenseParse:recs:recommendable', content: 'false' });
            } else {
              metas.push({ property: 'ntvnews-recommendable-article', content: 'true' });
              metas.push({ property: 'cXenseParse:recs:recommendable', content: 'true' });
            }
            const originalBroadCaster = getBroadcasterData(_.get(this.item, 'source_systems.project', 'n24'));
            const providerID = originalBroadCaster.title !== 'n24' ? originalBroadCaster.title.toUpperCase() : 'NTV';
            metas.push({ name: 'popIn:category', content: providerID });
            this.isExistArticleMetaRobots && metas.push({ name: 'robots', content: this.articleMetaRobots });

            this.isNoindex && metas.push({ name: 'robots', content: 'noindex' });

            const linkTags = this.props.getPreloadImages();

            return (
              <Helmet
                title={`${this.AD}${_.get(this.item, 'title')}${dayjsFormatDate}｜${titleTag}`}
                meta={metas}
                script={ldJsonList}
                link={linkTags}
              />
            );
          }}
        </HtmlContext.Consumer>
        <div className="articleDetail">
          <PlayerModule
            item={this.item}
            isLoaded={this.state.isLoaded}
            viewPlayerBtn={this.state.viewPlayerBtn}
            handlePlayButton={this.handlePlayButton}
            eachCompany={this.context.routeHandler.eachCompany}
            relatedArticles={this.relatedArticles}
            companyCode={this.props.companyCode}
          />
          {(_.get(this.item, 'hashTag') || _.get(this.item, 'featureTag')) && !this.context.routeHandler.listJson && (
            <KeyWord keywords={_.get(this.item, 'hashTag')} featureTag={_.get(this.item, 'featureTag')} />
          )}
          {/*_.get(this.state, 'viewAll') && !_.isEmpty(this.firstRelatedArticle) && (
              <p key={`content_paragraph_related_article`} className="player-text">
                （関連）<a href={this.firstRelatedArticle.url}>{this.firstRelatedArticle.title}</a>
              </p>
            )*/}
          <Share
            id={_.get(this.item, 'title')}
            title={_.get(this.item, 'title')}
            routeHandler={this.props.routeHandler}
            article
          />
        </div>
        {_.get(this.item, 'categoryCode') !== 'sponsored' && <SuggestArticleList articles={this.articles} invisibleAd={_.get(this.props, 'invisibleAd')} />}
        <Analytics
          categories={_.get(this.item, 'categoryTags')}
          path={canonical}
          pageTitle={gaTitle}
          env={_.get(this.context, 'models.config.data.env')}
          abbr={_.get(bloadcaster, 'abbr') || 'NTV'}
        />
        {/* <CxenseManager siteId={_.get(this.context, 'models.config.data.analytics.cxense_config.siteId')} /> */}
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(ArticlesContent);
root.getPaths = ArticlesContent.getPaths;
root.getRootPath = ArticlesContent.getRootPath;
root.getPrefetchPaths = ArticlesContent.getPrefetchPaths;
root.afterPrefetch = ArticlesContent.afterPrefetch;
export default root;
